
import http from "@/utils/axios";
import { url } from "@/utils/configUri";
import momentChainToken from "@/utils/json/momentChainToken.json" // New Data
import momentPriceFormular from "@/utils/json/momentPriceFormular.json" // New Data
import momentImageTemplate from "@/utils/json/momentImageTemplate.json"
import momentCreateTemplate from "@/utils/json/momentCreateTemplate.json"
import momentNFTAttrTemplate from "@/utils/json/momentNFTAttrTemplate.json" // Attr Data
import TaskConf from "@/utils/json/TaskConf.json"
import TagConfig from "@/utils/json/TagConfig.json"
import PresetTagConfig from "@/utils/json/PresetTagConfig.json"
import {
    getWeb3Config,
} from "@/utils/common";
import {
    isOnlineEnv,
    LfgMainnetId,
    LfgTestnetId,
} from "@/utils/env";
import {
    getLfgAllowance,
} from "@/utils/bidNftWeb3";
import {
    lfgApprove,
} from "@/utils/lfgStake";
import {getGasConfig} from "@/utils/gas";
import {ethers} from "ethers";
import {abi_momentClub} from "@/utils/abi";
import momentSettingValue from "@/utils/json/momentSettingValue.json" // Price Data
import store from "@/store";
import { gameMomentClubDetailPath, gameMomentClubExplorePath } from "@/routes/config";
const {dispatch, getState} = store;
const airDropItem = [
    {
        value: 2,
        tgeType: 2,
        canTge:true,
        momentType:0,
        label: 'Airdrop when 300 moments minted',
    }, {
        value: 1,
        tgeType: 1,
        momentType:1,
        canTge:true,
        label: 'Airdrop when 80 different moments minted',
    }, {
        value: 3,
        tgeType: 1,
        canTge:false,
        momentType:1,
        label: 'No airdrop',
    }
]


const getAirDropItemByValue = (val)=>{
    return airDropItem?.find(i=>i?.value === val) || {}
}

const coinTotalSupplyData = momentSettingValue.find(item => item?.value === 'coinTotalSupply')
const airdropPecentageData = momentSettingValue.find(item => item?.value === 'airdropPecentage')
const coinToNFTPecentageData = momentSettingValue.find(item => item?.value === 'coinToNFTPecentage')
const gentlePreferPecentageData = momentSettingValue.find(item => item?.value === 'gentlePreferPecentage')
const excitingPreferPecentageData = momentSettingValue.find(item => item?.value === 'excitingPreferPecentage')
const coinEachPackRangeRatioData = momentSettingValue.find(item => item?.value === 'coinEachPackRangeRatio')
const airdropTypeData = {
    1: gentlePreferPecentageData,
    2: excitingPreferPecentageData
}

const swapPresetPackNum = [1,5,10];
const swapPresetSellArr = [0.25,.5,.75,1]
const defaultAttr = momentNFTAttrTemplate.filter(item => item?.isDefault === 1)
const formatAttr = (attrs) => {
    let attr = []
    if(!Array.isArray(attrs)){
        attrs = [attrs]
    }
    attrs?.map(i => {
        const { Enum, Max, Min, TraitName, Type } = i;
        let enums = []
        if (Type === 'Categorical') {
            Enum.map(t => {
                const { trait_type, value } = t;
                if (value) {
                    enums.push({
                        enumName: value,
                        weight: 10
                    })
                }

            })
        }
        attr.push({
            attrName: TraitName,
            type: Type === 'Categorical' ? 2 : 1,
            minNum: Min,
            maxNum: Max,
            enumStrs: enums
        })
    })
    return attr
}

let preferencesItems = [],preferencesObj = {};

const websiteAfterAddon = {
    'x':'https://x.com/',
    't':'https://telegram.me/'
}

momentCreateTemplate.map(i=>{
    const { typeName, type} = i;
    if (preferencesObj[type]) {
        preferencesObj[type].push({
            ...i
        })
    } else {
        let obj = {}
        obj['' + type] = [
            {
                ...i
            }
        ]
        preferencesObj = {
            ...preferencesObj,
            ...obj
        }
        preferencesItems.push({
            value: type,
            label: typeName,
        })
    }
})
let attrMap = {};
momentNFTAttrTemplate.map(i=>{
    const {Template} = i;
    if (attrMap[Template]) {
        attrMap[Template].push(
            ...formatAttr(i)
        )
    } else {
        let obj = {}
        obj['' + Template] = [
            ...formatAttr(i)
        ]
        attrMap = {
            ...attrMap,
            ...obj
        }
    }
    

})


function stringToHex(str) {
    const val = [...str].map(c => c.charCodeAt(0).toString(16).padStart(2, 0)).join``
    return '0x' + val;
}

const calcTotalValue = (calcObj, a, b, n) => {
    const {formulaEnum:type,divParamC:c,mulParamD:d} = calcObj
    switch (type) {
        case 1:
            return (n * (n + 1) / 2 * a + n * b) / c * d;
        case 2:
            return (n * (n + 1) * (2 * n + 1) / 6 * a + n * (n + 1) / 2 * b) / c * d;
        case 3:
            return n * a / c * d;
    }
}

const getChainInfo = (chainId) => {
    return momentChainToken.find(i => i?.ID === chainId)
}

const getTagInfo = (tagId) =>{
    if(!tagId) return {}
    return TagConfig.find(i => i?.Id === tagId) || {}
}
const detailInfoShowHolderNum = 10;
const _isOnlineEnv = isOnlineEnv();
const creatClub = ({
    coinId,
    symbolName,
    symbolImageId,
    description,
    videoId,
    curveType,
    paramB,
    isFT,
    canTGE,
    withRarity,
    anonymous,
    preBuyCardNum,
    twitterLink,
    tgLink,
    activityTagId,
    customTags,
    tgeType
}) => {
    console.log(activityTagId,customTags)
    return new Promise((resolve, reject) => {
        http('post', url.moment_create, {
            coinId,
            symbolName,
            symbolImageId,
            description,
            videoId,
            curveType,
            paramB,
            isFT,
            canTGE,
            withRarity,
            anonymous,
            preBuyCardNum,
            twitterLink,
            tgLink,
            activityTagId,
            customTags,
            tgeType
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e?.toString() || 'Create Failed');
        })
    })

}
// New Data
let coinData = {}, chainData = [], coinList = [];
momentChainToken.map(item => {
    const { 
        isNative, 
        name, 
        coinName, 
        chainId, 
        isEnable, 
        isTestChain, 
        ID, 
        icon,
        kotBanner,
    } = item
    if (isEnable === 1 && ((_isOnlineEnv && !isTestChain) || (!_isOnlineEnv && isTestChain))) {
        if (coinData[chainId]) {
            coinData[chainId].push({
                value: isNative,
                label: coinName
            })
        } else {
            let obj = {}
            obj['' + chainId] = [
                {
                    value: isNative,
                    label: coinName
                }
            ]
            coinData = {
                ...coinData,
                ...obj
            }
            chainData.push({
                value: chainId,
                label: name,
            })
        }
        coinList.push({
            value: ID,
            chainId: chainId,
            name,
            coinName,
            label: `${name}-${coinName}`,
            icon,
            kotBanner: kotBanner,
        })
    }

})

let tagList = [{
    value: 0,
    chainId: 0,
    label: "No Tag & common Tag",
    icon:"",
}];

TagConfig.map(item=>{
    const {Id,tokenId,tag,icon,startTime,endTime} = item;
    const nowTime = new Date().getTime() / 1000
    if(nowTime>startTime && endTime > nowTime){
        tagList.push({
            value: Id,
            chainId: tokenId,
            label: tag,
            icon,
        })
    }
    
})

const momentClubList = (obj) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_clublist, obj).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getReferralcodeMomenttradeReflist = (cursor = 0) => {
    return new Promise((resolve, reject) => {
        http('post', url.referralcode_momenttrade_reflist, {
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentTgeNftRankList = ({
    clubUserId,
    rankType,
    rarityRange,
    onlyHeld,
    isAsc,
    cursor,
}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_tge_nftranklist, {
            clubUserId,
            rankType,
            rarityRange,
            onlyHeld,
            isAsc,
            cursor,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentTgeNftInfo = ({
    clubUserId,
    tokenId
}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_tge_nftinfo, {
            clubUserId,
            tokenId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const momentBuyCard = ({
    clubUserId,
    buyNum,
    costPrice,
}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_buycard, {
            clubUserId: clubUserId,
            buyNum: buyNum,
            costPrice: costPrice,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const momentSellCard = ({
    clubUserId,
    cardList,
}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_sellcard, {
            clubUserId: clubUserId,
            cardList: cardList,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentTgeNftCount = ({
    clubUserId
}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_tge_nftcount, {
            clubUserId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentGenImage = ({keyWord, genType = 1}) =>{
    return new Promise((resolve, reject) => {
        http('post', url.moment_genimage, {
            keyWord,
            genType,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentTgeNoticeList = () => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_tge_noticelist).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const setMomentTgeNoticeRead = (clubUserIds) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_tge_noticesetread, {
            clubUserIds: clubUserIds
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const momentTgeClublist = (obj) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_tgeclublist, obj).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const momentClubDetail = () => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_auction_refund, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const momentClubInfo = (clubUserId) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_clubinfo, {
            clubUserId: Number(clubUserId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const momentMintCoin = (clubUserId, openCardNum) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_mintcoin, {
            clubUserId: Number(clubUserId),
            openCardNum: Number(openCardNum)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const momentPreOpenpack = (clubUserId, cardList) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_pre_openpack, {
            clubUserId: clubUserId,
            cardList: cardList
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const momentMintResult = (chainId, txHash) =>{
    return new Promise((resolve, reject) => {
        http('post', url.moment_mintresult, {
            chainId: chainId,
            txHash: txHash
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const setFavorite = (userId, favorite) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_setfavor, {
            clubUserId: parseInt(userId),
            favorite,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const setLike = (userId, like) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_setlike, {
            clubUserId: parseInt(userId),
            like,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getRecommendClub = () => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_topprogress, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}


const searchclub = (searchKey, cursor, searchType) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_searchclub, {
            searchKey,
            searchType,
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentTradeInfo = (clubUserId, cardNum, isBuy) =>{
    return new Promise((resolve, reject) => {
        http('post', url.moment_tradeInfo, {
            clubUserId: clubUserId,
            cardNum: cardNum,
            isBuy: isBuy,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentTradevolInfo = () => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_tradevol_info, {
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentClubPackGasPrice = (wallets, coinInfo) =>{
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            const chainId = coinInfo?.chainId;
            await embeddedWallet.switchChain(chainId);
            const provider = await embeddedWallet.getEthersProvider();
            const signer = provider.getSigner();
            const gasData = await getGasConfig(signer);
            const value = ethers.utils.formatEther(gasData?.maxFeePerGas?.toString());
            resolve(value);
        } catch (e) {
            console.log("buyShares e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

const buyMomentClubPack = ({wallets,  clubId, amount,tgeType, expectedPrice, coinInfo}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const web3Config = await getWeb3Config();

            const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === coinInfo?.ID));

            if (!contractInfo){
                reject("get config error");
                return;
            }

            const chainId = coinInfo?.chainId;
            const contractAddr = contractInfo?.momentFactoryContract;

            await embeddedWallet.switchChain(chainId);
            console.log("contractAddr: " + contractAddr, "chainId: " + chainId);

            console.log(embeddedWallet);

            const provider = await embeddedWallet.getEthersProvider();

            const signer = provider.getSigner(); // ethers signer object

            const gasData = await getGasConfig(signer);

            const contract = new ethers.Contract(contractAddr, abi_momentClub, provider).connect(signer);

            if (coinInfo?.isNative === 0 && (coinInfo?.ID === LfgMainnetId || coinInfo?.ID === LfgTestnetId)) {
                let currentAllowance = await getLfgAllowance(embeddedWallet, contractAddr, contractInfo?.coinContract);
                console.log('[getLfgAllowance]', {currentAllowance: ethers.utils.formatEther(currentAllowance), expectedPrice});
                if (ethers.utils.formatEther(currentAllowance) < expectedPrice) {
                    let amount = ethers.utils.parseEther(Number.MAX_SAFE_INTEGER.toString());
                    await lfgApprove(wallets, amount, contractInfo?.coinContract, coinInfo?.chainId, contractAddr); //approve lfg for auction As MANY as Possible
                }
            }

            const cfg = {
                ...gasData,
                gasLimit: chainId === 11124 ? 100000000 : 25000000,
            };
            if (coinInfo?.isNative){
                
                // const _expectedPrice = ethers.utils.parseEther(expectedPrice)
                // const _entropyFee = ethers.utils.parseEther(entropyFee)
                cfg.value = ethers.utils.parseEther(expectedPrice)
                console.log(`cfg.val`,cfg.value);
                // console.log(`cfg.val`,cfg.value.toString());
            }

            console.log("buyMomentClubPack", {
                clubId, 
                amount, 
                expectedPrice, 
                coinInfo,
                cfg
            });

            contract.populateTransaction[tgeType === 1?'buyDrawCard':'buyFairCard']( clubId, amount, ethers.utils.parseEther(expectedPrice),cfg
            ).then(unsignedTx => {
                console.log("unsignedTx", unsignedTx);
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );

            }).catch(e => {
                console.log("buyShares e:" + e);
                reject(e);
            });
        } catch (e) {
            console.log("buyShares e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

const sellMomentClubPack = ({wallets, clubId, cardArr,amount,tgeType, amountArr, coinInfo}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const web3Config = await getWeb3Config();

            const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === coinInfo?.ID));

            if (!contractInfo){
                reject("get config error");
                return;
            }
            const chainId = coinInfo?.chainId;
            const contractAddr = contractInfo?.momentFactoryContract;

            await embeddedWallet.switchChain(chainId);
            console.log("contractAddr: " + contractAddr, "chainId: " + chainId);

            console.log(embeddedWallet);

            const provider = await embeddedWallet.getEthersProvider();

            const signer = provider.getSigner(); // ethers signer object

            const gasData = await getGasConfig(signer);

            const contract = new ethers.Contract(contractAddr, abi_momentClub, provider).connect(signer);

            if(tgeType === 1){
                console.log(`sellDrawCard--->`)
                contract.populateTransaction.sellDrawCard(clubId, cardArr, amountArr, {
                    ...gasData,
                }).then(unsignedTx => {
                    console.log("unsignedTx", unsignedTx);
                    signer.sendTransaction(unsignedTx).then(
                        resp => resolve(resp)
                    ).catch(
                        e => reject(e)
                    );
    
                }).catch(e => {
                    console.log("sellMomentClubPack e:" + e);
                    reject(e);
                });
            }else{
                console.log(`sellFairCard--->`,{
                    clubId, 
                    amount,
                    gasData
                })
                contract.populateTransaction.sellFairCard(clubId, amount, {
                    ...gasData,
                }).then(unsignedTx => {
                    console.log("unsignedTx", unsignedTx);
                    signer.sendTransaction(unsignedTx).then(
                        resp => resolve(resp)
                    ).catch(
                        e => reject(e)
                    );
    
                }).catch(e => {
                    console.log("sellMomentClubPack e:" + e);
                    reject(e);
                });
            }
            
        } catch (e) {
            console.log("sellMomentClubPack e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

const getActiveList = (clubUserId, cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_activity, {
            clubUserId:parseInt(clubUserId),
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const getMomentKingTgeList = ({coinIds}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_kingtgelist, {
            coinIds: coinIds
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const getMomentKingTgeWinnerList = ({coinId, cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_kingtgewinnerlist, {
            coinId: coinId,
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const getHolderList = (clubUserId,cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_holderlist, {
            clubUserId:parseInt(clubUserId),
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const getMomentSelfHoldList = ({cursor, skipClubUserId}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_selfholdlist, {
            cursor: cursor, 
            skipClubUserId: skipClubUserId,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentClubDntList = ({cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_club_dnt_list, {
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMomentTgeCanopenList = ({cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_tgecanopenlist, {
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const userTabList = ({tarUserId=0, rankTabType,rankSortType,isAsc,cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_userTabList, {
            tarUserId:Number(tarUserId),
            rankTabType,
            rankSortType,
            isAsc,
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const uploadImage = ({imageType,imageContent}) => {
    return new Promise((resolve, reject) => {
        http('post', url.upload_image, {
            imageType,
            imageContent,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const momentGenattrs = ({keyWord}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_genattrs, {
            keyWord,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const setAnony = (anonymous)=>{
    return new Promise((resolve, reject) => {
        http('post', url.moment_setanonymous, {
            anonymous,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const checkAttrs = (attrs)=>{
    const _attrs = [];
    if(!attrs || attrs?.length ===0) return _attrs
    attrs?.map(i=>{
        const {enumStrs,maxNum,minNum,type,attrName} = i;
        const _attrName = attrName.replace(/[^a-zA-Z0-9\s]/g, '');
        let _enumStrs = []
        if(type === 2){
            _enumStrs = enumStrs?.map(item => (
                {
                    ...item,
                    enumName: item?.enumName.replace(/[^a-zA-Z0-9\s]/g, '')
                }
            ))
        }
        _attrs.push({
            enumStrs:_enumStrs,
            maxNum,
            minNum,
            type,
            attrName:_attrName
        })
    })
    return _attrs

}

const getMomentChatUserList = ()=>{
    return new Promise((resolve, reject) => {
        http('post', url.moment_ownclublist, {
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const calcNumberCardVal = (type, a, b, x, c, d) => {
    switch (type) {
        case 1:
            return (a * x + b) / c * d
        case 2:
            return (a * Math.pow(x, 2) + b * x) / c * d;
        case 3:
            return a / c * d;
    }
}

const moment_tradelog = (params)=>{
    return new Promise((resolve, reject) => {
        http('post', url.moment_tradelog, params).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const video_upload_token = ({fileName, fileSize}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_video_upload_token, {
            fileName, fileSize
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const video_upload_token_refresh = ({videoId}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_video_upload_token_refresh, {
            videoId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const momentTwitterShare = ({clubUserId, cardNo, htmlTemplate}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_twitter_share, {
            clubUserId, 
            cardNo, 
            htmlTemplate
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const createClipTwitterHtml = ({creator, clubName, cardNo}) => {
    return `<!DOCTYPE html>
    <html>
    <head>
        <title>Moment Share</title>
        <meta name="twitter:card" content="player">
        <meta name="twitter:site" content="@MomentTrade">
        <meta name="twitter:title" content="Moment Share">
        <meta name="twitter:description" content="$${clubName} Moment #${cardNo}">
        <meta name="twitter:creator" content="@${creator}">
    
        <meta name="twitter:player" content="**twitter:player**">
        <meta name="twitter:player:width" content="450" />
        <meta name="twitter:player:height" content="800" />
    
        <meta name="twitter:image" content="**twitter:image**" />
        <meta name="twitter:image:type" content="**twitter:image:type**" />
        <meta name="twitter:image:alt" content="Moment Trade Sharing Image" />
    
        <meta name="twitter:domain" content="oss.mobigames.cn">
        <!--meta(http-equiv="refresh", content="0;URL='https://turnup.so/@"+name+"'")-->
    </head>
    
    <body>
    </body>
    
    </html>`;
}

const video_check = ({videoId}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_video_check, {
            videoId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const video_play_token = ({videoId}) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_video_play_token, {
            videoId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const moment_clubcardlist = (clubUserId) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_clubcardlist, {
            clubUserId:Number(clubUserId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const moment_owncardlist = (clubUserId) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_owncardlist, {
            clubUserId:Number(clubUserId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const moment_self_tradelogs = (clubUserId,cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_self_tradelogs, {
            clubUserId:Number(clubUserId),
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const moment_buyresult = (chainId,txHash) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_buyresult, {
            chainId:Number(chainId),
            txHash
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const moment_prebuyprice = (obj) => {
    return new Promise((resolve, reject) => {
        http('post', url.moment_prebuyprice, obj).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getTaskInfo = (id)=>{
    return TaskConf.find(i => i?.id === id)
}

const defaultMemeSwitchVideoParams = {
    coinId: 0,
    isAsc: false,
    lpRange: [0, -1],
    onlyFavorite: false,
    onlyHeld: false,
    onlyLive: false,
    priceRange: [0, -1],
    progressRange: [0, 100],
    rankType: 3,

    favoriteRange: [0, -1],
    marketCapRange: [0, -1],
    chainIds: coinList,
}

const goMomentClub = (navigate) => {
    let params = defaultMemeSwitchVideoParams;
    momentClubList({
        rankType: params.rankType,
        coinId: params.coinId,
        priceRange: params.priceRange,
        progressRange: params.progressRange,
        lpRange: params.lpRange,
        onlyFavorite: params.onlyFavorite,
        onlyHeld: params.onlyHeld,
        onlyLive: params.onlyLive,
        isAsc: params.isAsc,
        cursor: ""
    }).then(res => {
        let _list = []; 
        if (res?.clubList?.length > 0){
            for (let i = 0; i < res?.clubList?.length; i++){
                let stateCode = res?.clubList[i].profile.momentAuditCode;
                if (stateCode !== 2 || (stateCode === 2 && !getState().app.hideNSFW)){
                    _list.push({
                        clubId: res?.clubList[i].userId,
                        momentAuditCode: stateCode,
                        videoId: res?.clubList[i].videoId,
                        videoUrlMap: res?.clubList[i].videoUrlMap,
                        coverUrl: res?.clubList[i].profile.avatarUrl,
                        videoOriHeight: res?.clubList[i].videoOriHeight,
                        videoOriWidth: res?.clubList[i].videoOriWidth,
                        videoFirstFrameUrl: res?.clubList[i].videoFirstFrameUrl,
                    });
                }
            }
        }
        let index = -1;
        if (_list?.length > 0){
            for (let i = 0; i < _list?.length; i++){
                if (_list[i]?.momentAuditCode !== 3){
                    index = i;
                    break;
                }
            }
        }
        dispatch.app.setSwitchVideoList(_list)
        if (res?.nextCursor === ""){
            dispatch.app.setSwitchVideoListCursor("-1");
        }
        else{
            dispatch.app.setSwitchVideoListCursor(res?.nextCursor);
        }
        if (index >= 0){
            dispatch.app.setCurrentMomentClubId(_list[index].clubId);
            navigate(`${gameMomentClubDetailPath}`,{replace:true,state: {backPath:gameMomentClubExplorePath}});
            
        }
        else{
            navigate(gameMomentClubExplorePath);
        }
    }).catch(e => {
        console.error(e);
        navigate(gameMomentClubExplorePath);
    })
}

const taskRate = 10000;
const roundNumber = (input,decimalPlaces=0)=>{
    const num = parseFloat(input);

    if (isNaN(num)) {
        return 0;
    }

    const roundedNum = Math.ceil(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    const trimmedNum = parseFloat(roundedNum.toFixed(decimalPlaces));

    return trimmedNum;
}

export {
    creatClub,
    momentClubList,
    setFavorite,
    setLike,
    getRecommendClub,
    searchclub,
    momentClubInfo,
    momentMintCoin,
    getChainInfo,
    getActiveList,
    getMomentTradeInfo,
    getHolderList,
    getMomentClubPackGasPrice,
    buyMomentClubPack,
    sellMomentClubPack,
    getMomentSelfHoldList,
    getMomentClubDntList,
    userTabList,
    momentTgeClublist,
    getMomentTgeCanopenList,
    getMomentKingTgeList,
    getMomentKingTgeWinnerList,
    getMomentTgeNoticeList,
    setMomentTgeNoticeRead,
    getMomentTgeNftRankList,
    getMomentTradevolInfo,
    getMomentTgeNftInfo,
    getMomentTgeNftCount,
    getMomentGenImage,
    getReferralcodeMomenttradeReflist,
    momentBuyCard,
    momentSellCard,
    momentPreOpenpack,
    momentMintResult,
    createClipTwitterHtml,
    defaultMemeSwitchVideoParams,
    coinTotalSupplyData,
    airdropPecentageData,
    airdropTypeData,
    coinToNFTPecentageData,
    detailInfoShowHolderNum,
    momentPriceFormular,
    coinData,
    swapPresetPackNum,
    chainData,
    coinList,
    swapPresetSellArr,
    momentTwitterShare,
    momentChainToken,
    calcTotalValue,
    momentImageTemplate,
    preferencesItems,
    preferencesObj,
    coinEachPackRangeRatioData,
    uploadImage,
    defaultAttr,
    formatAttr,
    momentGenattrs,
    setAnony,
    checkAttrs,
    attrMap,
    websiteAfterAddon,
    calcNumberCardVal,
    getMomentChatUserList,
    moment_tradelog,
    video_upload_token,
    video_upload_token_refresh,
    video_check,
    video_play_token,
    moment_clubcardlist,
    moment_owncardlist,
    moment_buyresult,
    moment_prebuyprice,
    getTaskInfo,
    goMomentClub,
    taskRate,
    roundNumber,
    TagConfig,
    PresetTagConfig,
    getTagInfo,
    tagList,
    airDropItem,
    getAirDropItemByValue,
    moment_self_tradelogs,
}